<template>
  <th-page-wrapper>
    <th-datatable
      ref="table"
      sortable
      :headers="headers"
      :show-operations="false"
      :resource-query="resourceQuery"
      no-meta-check
      :buttons="computedButtons"
      :custom-resource="customResource"
      resource="analytics"
      route-base="/reports/financial_accounting/vat"
      :transform-fetched-data="transformData"
      show-filter
      :search-filters="filtersList"
      do-route-filters
      :locale="locale"
      prune-search-filters
      :export-options="{
        waitingContent: $t('common.interactions.download.waiting')
      }"
      headers-filterable
      :headers-config="headersConfig"
      @headers-config="handleHeadersConfig"
      @loading-error="handleLoadingError"
    />
  </th-page-wrapper>
</template>

<script>
import th from '@tillhub/javascript-sdk'
import qs from 'qs'
import pick from 'just-pick'
import safeGet from 'just-safe-get'
import { mapGetters } from 'vuex'
import { genRoundedPercent } from '@/utils/numbers'
import datatableHeadersConfig from '@/mixins/datatable-headers-config'
import { applyFiltersBeforeRouteEnter, getRangeFor } from '@/utils/date'
import { useExportsStore } from '@/store/exports'

export default {
  name: 'ReportsFinancialAccountingVAT',
  metaInfo() {
    return {
      title: this.$t('pages.vat.title')
    }
  },
  beforeRouteEnter: (to, _, next) => {
    // doing stuff here is very dangerous as it might lead to infinite route loops
    applyFiltersBeforeRouteEnter({ path: to.path, query: to.query, next })
  },
  beforeRouteUpdate(to, from, next) {
    // as UX enhancement we are going to persist some of the queries
    const elegibleObj = pick(safeGet(qs.parse(to.query), 'filter') || {}, [
      'date',
      'branch_group'
    ])
    this.$emit('route-filter', elegibleObj)

    next()
  },
  props: {
    resources: {
      type: Object,
      required: true
    }
  },
  setup() {
    const { headersConfig, handleHeadersConfig } = datatableHeadersConfig(
      'settings.headerFilters.reports.financial_accounting.vat'
    )
    return {
      headersConfig,
      handleHeadersConfig
    }
  },
  data() {
    return {
      isLegacy: true,
      headers: [
        {
          field: 'account',
          label: this.$t('pages.vat.account'),
          truncate: true,
          minWidth: 140,
          formatter: (row) => row.account
        },
        {
          field: 'account_number',
          label: this.$t('pages.vat.account_number'),
          truncate: true,
          minWidth: 160,
          formatter: (row) => row.account_number,
          sortType: 'number'
        },
        {
          field: 'vat_rate',
          label: this.$t('pages.vat.vat_rate'),
          align: 'right',
          truncate: true,
          minWidth: 180,
          // without rounding we get inaccuracies, e.g. 7.000000000000001
          formatter: (row) => genRoundedPercent(row.vat_rate),
          sortType: 'number'
        },
        {
          field: 'total_vat_amount',
          label: this.$t('pages.vat.total_vat_amount'),
          align: 'right',
          truncate: true,
          minWidth: 190,
          formatter: (row) => {
            if (Number.isFinite(row.total_vat_amount)) {
              return this.$formatCurrency(row.total_vat_amount, row.currency)
            }

            return '-'
          },
          sortType: 'currency'
        },
        {
          field: 'cart_item_count',
          label: this.$t('pages.vat.cart_item_count'),
          align: 'right',
          truncate: true,
          minWidth: 220,
          formatter: (row) => {
            if (Number.isFinite(row.cart_item_count)) {
              return row.cart_item_count
            }

            return '-'
          },
          sortType: 'number'
        },
        {
          field: 'total_amount_net',
          label: this.$t('pages.vat.total_amount_net'),
          align: 'right',
          truncate: true,
          minWidth: 140,
          formatter: (row) => {
            if (Number.isFinite(row.total_amount_net)) {
              return this.$formatCurrency(row.total_amount_net, row.currency)
            }

            return '-'
          },
          sortType: 'currency'
        },
        {
          field: 'total_amount_gross',
          label: this.$t('pages.vat.total_amount_gross'),
          align: 'right',
          truncate: true,
          minWidth: 140,
          formatter: (row) => {
            if (Number.isFinite(row.total_amount_gross)) {
              return this.$formatCurrency(row.total_amount_gross, row.currency)
            }

            return '-'
          },
          sortType: 'currency'
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      currentLocation: 'Config/getCurrentLocation',
      locale: 'Config/getLocale',
      defaultDateSelected: 'Config/getDefaultDateSelected'
    }),

    filtersList() {
      return [
        {
          name: 'branch_group',
          type: 'remote-search-select',
          doInitialFetch: true,
          label: this.$t('pages.reports.statistics.all.branch_group'),
          resource: 'branchGroups',
          filterable: true,
          optionsValue: 'id',
          disabled: !!this.currentLocation,
          computeName: this.$formatBranch,
          modifyQuery: (q) => ({
            q,
            deleted: false
          })
        },
        {
          name: 'date',
          type: 'daterange',
          prop: ['start', 'end'],
          label: this.$t('pages.vat.all.filters.date.label'),
          closable: false,
          noFutureDates: true,
          formatValue: (value) => this.$date.formatDateRange(value),
          default: getRangeFor[this.defaultDateSelected]?.(),
          modifyFilter: (filterObject) => ({
            start: filterObject.start,
            end: filterObject.end
          })
        }
      ]
    },
    branchNumber() {
      let branchNumber
      if (this.currentLocation) {
        branchNumber = (this.resources.branches.get(this.currentLocation) || {})
          .branch_number
        if (!Number.isFinite(branchNumber) && !branchNumber) {
          const err = new TypeError(
            'Could not find branch number in resources but required one'
          )
          this.$logException(err, {
            message: this.$t('pages.transactions.errors.branch_number_missing')
          })
          throw err
        }
      }
      return branchNumber
    },
    resourceQuery() {
      return {
        legacy: this.isLegacy,
        query: {
          branch_custom_id: this.branchNumber || undefined
        }
      }
    },
    customResource() {
      return th.analytics().vat()
    },
    buttons() {
      return [
        {
          type: 'custom_export',
          scopes: ['reports_financial_accounting:vat:export'],
          clickHandler: ({ handleDownload, resourceOptions }) => {
            this.handleExport({ handleDownload, resourceOptions })
          }
        }
      ]
    },
    computedButtons() {
      return this.buttons.filter((b) =>
        b.scopes ? this.$checkPermissions({ scopes: b.scopes }) : true
      )
    }
  },
  mounted() {
    this.$emitter.on('refresh-requested', () => {
      this.$refs.table.refresh()
    })
  },
  beforeUnmount() {
    this.$emitter.off('refresh-requested')
  },
  methods: {
    handleLoadingError(err) {
      this.$logException(err, {
        trackError: false,
        message: this.$t('common.error.action.read.multiple', {
          resources: this.$t('pages.vat.title')
        })
      })
    },
    transformData(data) {
      return data[0].values
    },
    async handleExport({ resourceOptions }) {
      const query = {
        ...resourceOptions,
        query: {
          ...resourceOptions.query,
          format: 'csv',
          filename_prefix: this.$t('pages.vat.title'),
          timezone: this.timeZone || 'Europe/Berlin'
        }
      }

      try {
        const {
          data
        } = await th
          .analyticsHandlersV1()
          .analytics.reports.AnalyticsReportsVat.getAll(query)

        const exportId = data?.[0]?.correlationId
        if (!exportId) {
          throw new Error(`Response data or correlation ID is missing`)
        }

        useExportsStore().setNewExport({
          exportId,
          payload: {
            originKey: 'pages.vat.title',
            date: new Date(),
            action: {
              entity: 'analyticsHandlersV1',
              path: 'analytics.reports.AnalyticsReportsVat',
              handler: 'getAll',
              query
            }
          }
        })
      } catch (err) {
        this.$logException(err, {
          message: this.$t('notifications.exports.error.text', {
            entity: this.$t('pages.vat.title')
          })
        })
      }
    }
  }
}
</script>
